import React from "react";

import { SEO } from "@parataxic/shared-ui";

import "./about.css";

const IndexPage = (): React.ReactNode => (
  <>
    <SEO title="About Parataxic" />

    <div className="about" role="main">
      <h1>Study time!</h1>
      <div className="about__content">
        <div className="about__content__line">When you were little,</div>
        <div className="about__content__line">
          How much undivided attention did your parents give you? Did they
          listen?
        </div>
        <div className="about__content__line">
          Was it about understanding your world, or fitting you in their own?
        </div>
        <div className="about__content__line">
          Did they hold you in positive regard as a person, even when you made
          mistakes?
        </div>
        <div className="about__content__line">
          If someone entered into your life and paid you quality time, and
          listened, would you accept and trust it?
        </div>
        <div className="about__content__line">
          We recreate our relationships based on the familiar, for better and
          worse. The dynamic flows through persons, institutions, and
          generations.
        </div>
        <div className="about__content__line">
          I study theories of personality, object relations, and couple/family
          therapy, genograms (family trees). Here I revise overviews of my
          favorite theories and resources worth sharing.
        </div>
      </div>
    </div>
  </>
);

export default IndexPage;
